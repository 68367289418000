<template>
  <div>
    <div class="vx-row">

      <div class="vx-col md:w-1/4 w-full mb-1">
        <vx-card title="CSV importieren">
          <div class="centerx">
            <vs-row>
              <vs-col vs-type="flex" vs-align="center" vs-w="12">
                <h6 class="mb-4">CSV Daten importieren:</h6>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-row>
                <vs-col vs-type="flex" vs-align="center" vs-w="12">
                  <input type="file" id="file" ref="file" v-on:change="uploadFiles"/>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-type="flex" vs-align="center" vs-w="12">
                  <mg-select v-model="datatype" track-by="id" label="name" :options="dropDownDatas"></mg-select>
                </vs-col>
              </vs-row>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="right" vs-w="12">
                <vs-button class="mr-8 mb-4" :disabled="validateForm()" @click="createData()">Datei hochladen
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </vx-card>
      </div>
      <div class="vx-col md:w-3/4 w-full mb-1">
        <vx-card title="Zuletzt importiert">
          <vue-bootstrap4-table :rows="data_import" :columns="columns" :config="config">

            <template slot="actions" slot-scope="props">
              <div v-if="props.row.type == 'banktransactions'">
                <span @click="validateBankTransactionFile(props.row.id)" class="link" >Validate</span>
                <vs-button color="primary"
                           type="filled"
                           icon="delete_forever"
                           size="small"
                           @click="removeFile(props.row.id)"
                ></vs-button>
              </div>
              <div v-else>

              </div>
            </template>

            <template slot="empty-results">
              Keine Dateien gefunden
            </template>
          </vue-bootstrap4-table>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
    import ApiService from "../../../api/index";
    import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table';
    import MgSelect from "../../../components/mg-select/MgSelect";
    import * as qs from "qs";
    import QueryHelper from "@/mixins/helper/query.helper";

    export default {
        components: {
            VueBootstrap4Table,
            MgSelect,
        },
        data() {
            return {
                datatype: '',
                files: [],
                attachment: null,
                dropDownDatas: [
                    {id: 'customers', name: 'Kunden'},
                    {id: 'contacts', name: 'Kontakte'},
                    {id: 'contracts', name: 'Verträge'},
                    {id: 'bills', name: 'Rechnungen'},
                    {id: 'deals', name: 'Angebote'},
                    {id: 'suppliers', name: 'Lieferanten'},
                    {id: 'banktransactions', name: 'Banktransaktionen'},
                ],
                data_import: [],
                dataImport: [],
                columns: [
                    {
                        label: "id",
                        name: "id",
                        sort: true,
                        hide: false,
                        uniqueId: true
                    }, {
                        label: "User",
                        name: "user.first_name",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left'
                    },
                    {
                        label: "Datei",
                        name: "file",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left'
                    },
                    {
                        label: "Type",
                        name: "type",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left'
                    },
                    {
                        label: "Hochgeladen",
                        name: "created_at",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left'
                    },
                    {
                        label: "Aktionen",
                        name: "actions",
                        slot: "actions",
                        sort: false,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left'
                    },
                ],
                config: {
                    checkbox_rows: false,
                    rows_selectable: true,
                    show_refresh_button: false,
                    show_reset_button: false,
                    highlight_row_hover_color: '#f8f8f8',
                    inlineEditChange: false,
                    pagination: true,
                    per_page: 30,
                  server_mode: true,
                },
              queryParams: {
                sort: [{
                  caseSensitive: true,
                  name: "created_at",
                  order: "desc"
                }],
                filters: [],
                global_search: "",
                per_page: 25,
                page: 1,
              },
            }
        },
        created() {
            this.fetchImportData();
        },
        methods: {
            fetchImportData() {
              let queryParams = QueryHelper.parseRequestParams(this.queryParams);

                ApiService.get('data-import', {
                  params: queryParams,
                  paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    this.data_import = response.data.result.result;
                }).catch(response => {
                })
            },
            uploadFiles(e) {

                const files = e.target.files || e.dataTransfer.files;

                if (!files.length) {
                  return;
                }

                console.log(files)

                /* checken ob file .csv ist */
                if (files[0].type !== "text/csv") {
                    this.$vs.notify({
                        title: 'Wrong File',
                        text: 'Only supported .csv Files!',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'warning'
                    });
                }

                this.attachment = files[0];

            },
            validateForm() {
                return false;
            },
            createData() {
                let checkFile = this.attachment;
                if (checkFile === "" || checkFile === "undefined" || checkFile.length === 0 || this.datatype === "") {
                    this.$vs.notify({
                        title: 'Wrong File',
                        text: 'Please fill in all Fields',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'warning'
                    });
                } else {
                    this.$vs.loading();

                  let formData = new FormData();

                  formData.append('csv_file', this.attachment.name);
                  formData.append('csv_type', this.datatype);
                  formData.append('file', this.attachment);


                  ApiService.post('data-import', formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((response) => {
                    this.$vs.loading.close();

                    this.$vs.notify({
                      title: 'Erfolgreich',
                      text: 'Die Datei wurde hochgeladen',
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'success'
                    });
                    this.fetchImportData();
                    if (this.datatype != 'banktransactions'){
                      this.$router.push('/data-import/step2');
                    }
                  }).catch((error) => {
                    this.$vs.loading.close();

                    if (error.response.data.message) {
                      this.$vs.notify({
                        title: 'Fehler',
                        text: error.response.data.message,
                        time: 6000,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                      })
                    } else {
                      this.$vs.notify({
                        title: 'Fehler',
                        text: error.message,
                        time: 6000,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                      })
                    }

                  });


                }
            },
          validateBankTransactionFile(id){
            this.$router.push(`data-import/validate-bank-transactions/`+id);
          },
          removeFile(id){
            ApiService.delete('data-import/' + id).then((response) => {

              this.fetchImportData();

              if (response.data.status === 'success') {
                return this.$vs.notify({
                  title: 'Erfolgreich gelöscht',
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success'
                });
              }

              this.$vs.loading.close()

              this.$vs.notify({
                title: 'Fehlgeschlagen',
                text: 'Konnte nicht gelöscht werden.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            }).catch((error) => {
              this.$vs.notify({
                title: 'Fehlgeschlagen',
                text: 'Konnte nicht gelöscht werden.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            })
          }
        }
    }
</script>


<style>
  .con-input-upload {
    height: 37px;
    width: 95%;
  }

  .material-icons {
    float: right;
  }

  .vs-row {
    margin-bottom: 10px;
  }
</style>
